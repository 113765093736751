<template>
  <form class="additional-manager">
    <div class="additional-manager__content">
      <div class="title" v-html="$t('additional_manager.title')"></div>
      <template v-if="!additionalManagerStatus || additionalManagerStatus === 'deleted'">
        {{ additionalManagerStatus }}
        <div class="desc">
          {{ managerFor === 'vkontakte' ? $t('additional_manager.add_link_vk') : $t('additional_manager.add_user_email') }}
        </div>
        <input
          class="additional-manager__input"
          type="text"
          :placeholder="managerFor === 'vkontakte' ? $t('additional_manager.link_to_profile') : 'E-mail'"
          v-model="form.email"
          required
        >

        <div v-if="errors.email" class="additional-manager__input-error" v-text="errors.email" />

        <div v-if="authErrors && authErrors.email" class="additional-manager__input-error">
          <p v-for="(err, index) in authErrors.email" :key="index">
            {{ err }}
          </p>
        </div>
      </template>

      <template v-else>
        <div class="additional-manager__item">
          <div class="additional-manager__item-block">
            <div class="additional-manager__item-name">{{ additionalManagerName }}</div>
            <div class="additional-manager__item-status">{{ additionalManagerStatus }}</div>
          </div>

          <div @click="confirmDelete" class="additional-manager__item-delete">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.397 6.35771C14.5521 6.35771 14.6928 6.42731 14.804 6.5449C14.9076 6.6705 14.9598 6.8265 14.9447 6.9913C14.9447 7.0457 14.5302 12.4376 14.2934 14.7072C14.1452 16.1 13.2722 16.9456 11.9629 16.9688C10.9561 16.992 9.97194 17 9.00295 17C7.97421 17 6.96815 16.992 5.9916 16.9688C4.7261 16.9376 3.85242 16.0768 3.71172 14.7072C3.46815 12.4296 3.0612 7.0457 3.05363 6.9913C3.04607 6.8265 3.0975 6.6705 3.20189 6.5449C3.30477 6.42731 3.45303 6.35771 3.60885 6.35771H14.397ZM10.6059 1C11.2935 1 11.9077 1.4936 12.0855 2.19759L12.2126 2.78158C12.3154 3.25758 12.7163 3.59437 13.1778 3.59437H15.4455C15.7481 3.59437 16 3.85277 16 4.18157V4.48557C16 4.80636 15.7481 5.07276 15.4455 5.07276H2.55522C2.25189 5.07276 2 4.80636 2 4.48557V4.18157C2 3.85277 2.25189 3.59437 2.55522 3.59437H4.823C5.28366 3.59437 5.68457 3.25758 5.7882 2.78238L5.90696 2.23679C6.09153 1.4936 6.69894 1 7.3941 1H10.6059Z" fill="#EB5757"/>
            </svg>
            <span>{{ $t('additional_manager.delete') }}</span>
          </div>
        </div>

        <div v-if="confirm" class="additional-manager__confirm">
          <div class="additional-manager__confirm-title">{{ $t('additional_manager.delete') }}</div>
          <div class="additional-manager__confirm-text">{{ $t('additional_manager.confirm_delete') }}?</div>

          <div class="additional-manager__confirm-bottom">
            <div @click="deleteManager" class="additional-manager__confirm-delete">{{ $t('additional_manager.delete')}}</div>
            <div @click="confirm = false" class="additional-manager__confirm-cancel">{{ $t('additional_manager.cancel')}}</div>
          </div>
        </div>

        <div v-if="additionalManagerError" class="additional-manager__error">
          <div class="additional-manager__error-title">{{ $t('additional_manager.error')}}</div>
          <div class="additional-manager__error-text">{{ $t('additional_manager.cannot_add_manager')}} ({{ additionalManagerError }})</div>
        </div>
      </template>
    </div>

    <div class="additional-manager__final">
      <ButtonElement
        :disabled="!form.email"
        class="additional-manager__btn"
        :text="$t('button_props.append')"
        @click-button="saveData"
      />
    </div>
  </form>
</template>

<script>
import formValidate from '../../mixin/formValidate'
import ButtonElement from "@/components/elements/ButtonElement";

export default {
  name: "AdditionalManager",
  components: {
    ButtonElement,
  },
  mixins: [formValidate],
  data() {
    return {
      form: {
        email: '',
      },
      errors: {
        email: '',
      },
      id: '',
      currentPlatform: '',
      confirm: false,
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    additionalManagerName() {
      return this.$store.state.Ad.additionalManagerName
    },
    additionalManagerError() {
      return this.$store.state.Ad.additionalManagerError
    },
    additionalManagerStatus() {
      return this.$store.state.Ad.additionalManagerStatus
    },
    additionalManagerId() {
      return this.$store.state.Ad.additionalManagerId
    },
    managerFor() {
      return this.$store.state.Dialog.managerFor
    },
  },
  watch: {
    activeAccount: {
      deep: true,
      handler(val) {
        if (val) {
          this.errors = null
          this.showInput = false
          this.currentPlatform = this.platformsList[0].slug
          this.getAccounts(this.platformsList[0].slug, val.id)
        }
      }
    },
    additionalManagerStatus() {
      return this.$store.state.Ad.additionalManagerStatus
    },
  },
  methods: {
    validateForm () {
      if (!this.form.email) {
        this.errors.email = this.$t('additional_manager.not_empty')
      } else {
        if (this.managerFor === 'mytarget') {
          this.validateEmail()
        } else {
          if (!this.form.email.startsWith('http')) {
            this.errors.email = this.$t('additional_manager.value_link')
          }
        }
      }
    },
    saveData() {
      this.validateForm()
      if (this.isError) {
        return false
      }
      this.$store.dispatch('Ad/addAdditionalManager', {
        id: this.activeAccount.id,
        data: {
          service: this.managerFor,
          manager_name: this.form.email,
        }
      }).then(() => {
        this.form.email = ''
      }).catch(() => {
        this.$notify({
          message: this.$t('additional_manager.belong_to_another_account'),
          type: 'error'
        });
      })
    },
    confirmDelete() {
      this.confirm = true
    },
    deleteManager() {
      this.confirm = false
      this.$store.dispatch('Ad/deleteAdditionalManager', {
        id: this.activeAccount.id,
        idManager: this.additionalManagerId,
      }).then(() => {
        this.$notify({
          message: this.$t('additional_manager.success'),
          type: 'success'
        });
      }).catch(() => {
        this.$notify({
          message: this.$t('additional_manager.try_later'),
          type: 'error'
        });
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.additional-manager {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8rem 7rem 7rem;

  &__content {
    padding: 0 0 7rem;
  }

  .title {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.8rem;
    color: $basicBlack;
    margin: 0 0 4rem;
  }

  .desc {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.9rem;
    color: $basicBlack;
    margin: 0 0 1.6rem;
  }

  &__input {
    width: 100%;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $basicBlack;
    outline: none;
    border: 1px solid $gray4;
    border-radius: 2px;
    transition: border-color .4s;
    padding: 1.5rem;

    &::placeholder {
      color: $gray2;
      transition: opacity .4s;
    }

    &:focus {
      border-color: $primary;

      &::placeholder {
        opacity: 0;
      }
    }

    &-error {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $redClassic;
      margin: 1.2rem 0 0;
    }
  }

  &__final {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    width: 100%;
    height: auto;
    outline: none;
    padding: 1.5rem 3rem;

    &:hover, &:focus {
      border: 1.5px solid $primaryHover;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid $gray4;
    padding: 0 0 1.6rem;
    margin: 0 0 4rem;

    &-name {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.9rem;
      color: $primary;
      margin: 0 0 .8rem;
    }

    &-status {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $grey1;
    }

    &-delete {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: transparent;
      outline: none;
      border: none;

      svg {
        display: block;
        height: auto;
        margin: 0 .8rem 0 0;

        path {
          transition: fill .4s;
        }
      }

      span {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $redLight;
        transition: color .4s;
      }

      &:hover {

        svg {

          path {
            fill: $redClassic;
          }
        }

        span {
          color: $redClassic;
        }
      }
    }
  }

  &__error, &__confirm {
    margin-bottom: 4rem;

    &-title {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.9rem;
      margin: 0 0 .8rem;
    }

    &-text {
      font-size: 1.6rem;
      line-height: 1.9rem;
      word-break: break-word;
    }

    &-bottom {
      display: flex;
      align-items: center;
      margin-top: 2.4rem;
    }

    &-delete, &-cancel {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.9rem;
      color: $basicWhite;
      background-color: $redLight;
      border-radius: 4px;
      padding: .8rem 5rem;
      transition: background-color .4s, color .4s;
      margin-right: 2rem;

      &:hover {
        background-color: $redClassic;
      }
    }

    &-cancel {
      color: $grey1;
      background-color: transparent;
      padding-right: 2.5rem;
      padding-left: 2.5rem;

      &:hover {
        color: $basicWhite;
        background-color: $grey1;
      }
    }
  }
}

@include below(769px) {
  .additional-manager{
    padding: 15px;
    .title{
      margin-right: 30px;
      word-break: break-word;
      br{
        display: none;
      }
    }
  }
}
</style>
